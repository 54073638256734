import React from "react";
import { Redirect } from "react-router-dom";
import ConstantList from "app/appConfig";
import { useTranslation } from "react-i18next";

export default function Index() {
  const { i18n } = useTranslation();
  React.useEffect(() => {
    i18n.changeLanguage("en");
  }, []);

  return <Redirect to={ConstantList.ROOT_PATH} />;
}

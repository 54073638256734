import React from "react";
import "./styles.scss";
import { withTranslation } from "react-i18next";

class Toggle extends React.Component {
  state = {
    selected: "vi",
  };

  toggle() {
    const { i18n } = this.props;
    const selected = this.state.selected;
    const value = selected === "vi" ? "en" : "vi"
    i18n.changeLanguage(value);
    this.setState({ selected: value });
  }
  
  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.i18n.language !== this.state.selected) {
      this.setState({ selected: nextProps.i18n.language });
    }
  }

  render() {
    const className = `toggle-component ${this.state.selected !== "vi" ? " active" : ""}`;
    return (
      <div className={className} onClick={() => this.toggle()}>
        <div className="toggle-button" />
      </div>
    );
  }
}

export default withTranslation()(Toggle);
const APPLICATION_PATH = "/";
const DEFAULT_ROOT_MODULES = [
    {
      title: "LAO TIỀM ẨN",
      to: null, //-> alert tinh nang dang phat trien
      auth: ["ROLE_LTA_MANAGER"],
      trans: "lta"
    },
    {
      title: "LAO NHẠY CẢM",
      to: "https://dstbnewvitimes.globits.net/",
      auth: ["ROLE_DS_TB_MANAGER"],
      trans: "lnc"
    },
    {
      title: "LAO KHÁNG THUỐC",
      to: "http://localhost:3000",
      auth: ["ROLE_LKT_MANAGER"],
      trans: "lkt"
    },
    {
      title: "DASHBOARD",
      to: "http://localhost:3000",
      auth: ["ROLE_ADMIN"],
      trans: "dashboard"
    },
    {
      title: "QUẢN LÝ \n CUNG ỨNG",
      to: null,
      auth: ["ROLE_STORE_MANAGER"],
      trans: "store"
    },
]
module.exports = Object.freeze({
    AUTH_MODE: window.Configs.AUTH_MODE || "Keycloak",
    ROOT_PATH: window.Configs.ROOT_PATH || APPLICATION_PATH,
    ACTIVE_LAYOUT: window.Configs.ACTIVE_LAYOUT || "layout1", //layout1 = vertical, layout2=horizontal
    API_ENPOINT: window.Configs.API_ENPOINT || "https://localhost:8071", //local
    //  API_ENPOINT: "https://newvitimes.globits.net:8092",
    LOGIN_PAGE: APPLICATION_PATH + "session/signin", //Nếu là Spring
    HOME_PAGE: APPLICATION_PATH + "dashboard", //Nếu là Spring
    ROOT_MODULES: window.Configs.ROOT_MODULES || DEFAULT_ROOT_MODULES
});
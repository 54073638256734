import axios from "axios";
import localStorageService from "./localStorageService";
import ConstantList from "../appConfig";
import UserService from "../services/UserService";
import history from "history.js";

const config = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    Authorization: "Basic Y29yZV9jbGllbnQ6c2VjcmV0",
  },
};

class JwtAuthService {
  // user = {
  //   userId: "1",
  //   role: "ADMIN",
  //   displayName: "Watson Joyce",
  //   email: "watsonjoyce@gmail.com",
  //   photoURL: ConstantList.ROOT_PATH + "assets/images/avatar.jpg",
  //   age: 25,
  //   token: "faslkhfh423oiu4h4kj432rkj23h432u49ufjaklj423h4jkhkjh",
  // };

  getCurrentUser = () => {
    let url = ConstantList.API_ENPOINT + "/api/user/ext/getCurrentUserName";
    return axios.get(url).then((response) => {
      return this.setUser(response.data).then(() => {
        return response.data;
      })
    })
  };

  loginWithUserNameAndPassword = (username, password) => {
    let requestBody =
      "client_id=core_client&grant_type=password&client_secret=secret";
    requestBody =
      requestBody + "&username=" + username + "&password=" + password;
    return axios
      .post(ConstantList.API_ENPOINT + "/oauth/token", requestBody, config)
      .then((response) => {
        console.log(response);
        const tokenExpriredTime = new Date(
          Date.now() + response.data.expires_in * 1000
        );

        localStorageService.setItem("tokenExpiredTime", tokenExpriredTime);

        this.setSession(response.data.access_token);
        this.setLoginUser(response.data);
      });
  };

  loginWithEmailAndPassword = (email, password) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.user);
      }, 1000);
    }).then((data) => {
      this.setUser(data);
      this.setSession(data.token);
      return data;
    });
  };

  loginWithToken = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.user);
      }, 100);
    }).then((data) => {
      this.setSession(data.token);
      this.setUser(data);
      return data;
    });
  };

  logout = () => {
    if (ConstantList.AUTH_MODE === "Keycloak") {
      UserService.doLogout();
      this.setSession(null);
      this.removeUser();
    } else {
      this.setSession(null);
      this.removeUser();
    }
  };

  setSession(token) {
    if (token) {
      localStorageService.setItem("jwt_token", token);
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    } else {
      localStorage.removeItem("jwt_token");
      delete axios.defaults.headers.common["Authorization"];
    }
  }

  setLoginUser = (data) => {
    let user = {};
    user.token = data.access_token;
    localStorageService.setItem("auth_user", user);
    return user;
  };

  //set token
  setLoginToken = (data) => {
    localStorageService.setItem("auth_token", data);
  };

  setUser = async (user) => {
    this.removeUser()
    localStorageService.setItem("auth_user", user);
    if (user?.roles?.some(role => role.authority === "ROLE_ADMIN")) {
      localStorageService.setItem("is_admin", true);
    }
    if (user?.roles?.some(role => role.authority === "ROLE_LABORATORY")) {
      localStorageService.setItem("is_lab", true);
    }
    if (user?.roles?.some(role => role.authority === "ROLE_WARD")) {
      localStorageService.setItem("is_ward", true);
    }
  };

  removeUser = () => {
    localStorage.removeItem("auth_user");
    localStorage.removeItem("is_org");
    localStorage.removeItem("list_org");
    localStorage.removeItem("current_org");
    localStorage.removeItem("is_admin");
    localStorage.removeItem("is_lab");
    localStorage.removeItem("is_department");
    localStorage.removeItem("is_ward");
    localStorage.removeItem("list_admin_unit");
    localStorage.removeItem("current_admin_unit");
    localStorage.removeItem("is_region");
    localStorage.removeItem("is_province");
    localStorage.removeItem("is_district");
  };
}

export default new JwtAuthService();

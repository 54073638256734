import ConstantList from "app/appConfig";
import lang from "./index"

const Routes = [
  {
    path: ConstantList.ROOT_PATH + "en",
    exact: true,
    component: lang,
  },
];

export default Routes;

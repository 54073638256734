import React from "react";
import styles from "./styles.module.scss";
import { withTranslation } from "react-i18next";
import Toggle from "./ToogleButton";

class LanguageSelect extends React.Component {
  state = {
    selected: "vi",
    hasError: false,
  };

  handleChange(value) {
    const { i18n } = this.props;
    i18n.changeLanguage(value);
    this.setState({ selected: value });
  }
  render() {
    const { t } = this.props;
    const { selected } = this.state;
    return (
      <form className={styles.root} autoComplete="off">
        <div className={styles.formControl}>
          {/* <label for="name">{t("general.language")}</label> */}
          {/* <select
            name="name"
            id="name"
            onChange={event => this.handleChange(event.target.value)}
            value={selected}
          >
            <option value="vi">Tiếng Việt</option>
            <option value="en">English</option>
          </select> */}
          <label for="name">{t("lang")}</label>
          <Toggle />
        </div>
      </form>
    );
  }
}

export default withTranslation()(LanguageSelect);

import React from "react";
import { Redirect } from "react-router-dom";
import dashboardRoutes from "./views/dashboard/DashboardRoutes";
import ConstantList from "./appConfig";
import langRoutes from "./views/lang/routes"

const redirectRoute = [
  {
    path: ConstantList.ROOT_PATH,
    exact: true,
    component: () => <Redirect to={ConstantList.ROOT_PATH} />, //Luôn trỏ về HomePage được khai báo trong appConfig
  },
];

let routes = [
  ...dashboardRoutes,
  ...redirectRoute,
  ...langRoutes,
];

export default routes;

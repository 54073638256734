// import Cookies from 'universal-cookie';

class localStorageService {
  ls = window.localStorage

  removeItem = key => {
    this.ls.removeItem(key);
  }

  setItem(key, value) {
    // const cookies = new Cookies();

    value = JSON.stringify(value)
    this.ls.setItem(key, value)
    //cookies.set(key, value, { path: '/' });
    return true
  }

  getItem(key) {
    //  const cookies = new Cookies();
    let value = this.ls.getItem(key)
    //let value = cookies.get(key);
    try {
      return JSON.parse(value)
    } catch (e) {
      return null
    }
  }
  getLoginUser(){
    return this.getItem("auth_user");
  }
  isAdmin() {
    return this.getItem("is_admin")
  }
  isOrg() {
    return this.getItem("is_org")
  }
  getListOrg() {
    return this.getItem("list_org")
  }
  getCurrentOrg() {
    return this.getItem("current_org")
  }
  isLab() {
    return this.getItem("is_lab")
  }
  isDepartment() {
    return this.getItem("is_department")
  }
  isWard() {
    return this.getItem("is_ward")
  }
  isProvince() {
    return this.getItem("is_province")
  }
  getAdminUnit() {
    return this.getItem("current_admin_unit")
  }
  isDistrict() {
    return this.getItem("is_district")
  }
  isRegion() {
    return this.getItem("is_region")
  }
}

export default new localStorageService();
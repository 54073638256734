import React, { Component, Fragment } from "react";
import jwtAuthService from "../services/jwtAuthService";
import localStorageService from "../services/localStorageService";
import history from "history.js";
import ConstantList from "../appConfig";
import axios from "axios";
let timeout;

class Auth extends Component {
  state = {
    getUserComplete: false,
  };

  constructor(props) {
    super(props);
    axios.interceptors.response.use(
      response => {
        //2 tiếng nếu không có request thì tự động logout
        if (timeout) {
          clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
          jwtAuthService.setSession(null);
          jwtAuthService.removeUser();
          setTimeout(() => {
            jwtAuthService.logout();
          }, 3000)
        }, 7200000)
        return response
      },
      error => {
        if (401 === error?.response?.status) {
          jwtAuthService.setSession(null);
          jwtAuthService.removeUser();
          setTimeout(() => {
            jwtAuthService.logout();
          }, 3000)
          return Promise.reject(error);
        }
        return Promise.reject(error);
      }
    );

    if (ConstantList.AUTH_MODE === "Keycloak") {
      let userToken = localStorageService.getItem("auth_token");
      if (userToken != null) {
        jwtAuthService.setSession(userToken);
        jwtAuthService.getCurrentUser().finally(() => {
          this.setState({ getUserComplete: true });
        })
      } else {
        history.push(ConstantList.LOGIN_PAGE)
      }
    } else {
      let user = localStorageService.getItem("auth_user");

      if (user != null) {
        jwtAuthService.setSession(user.token);
      } else {
        history.push(ConstantList.LOGIN_PAGE)
      }
    }
  }

  checkJwtAuth = () => {
    jwtAuthService.loginWithToken()
  };

  render() {
    const { children } = this.props;
    const { getUserComplete } = this.state;
    if (ConstantList.AUTH_MODE === "Keycloak") {
      return (getUserComplete &&
        <Fragment>{children}</Fragment>
      );
    } else {
      return <Fragment>{children}</Fragment>;
    }
  }
}

export default Auth;

import React, { useEffect, useMemo, useState } from "react";
import { ROOT_MODULES } from "app/appConfig";
import "./Dashboard.scss";
import jwtAuthService from "app/services/jwtAuthService";
import Card from "./component/Card";
import localStorageService from "app/services/localStorageService";
import LanguageSelect from "app/components/LanguageSelect/LanguageSelect";
import { useTranslation } from "react-i18next";

export default function DashboardIndex() {

  const user = localStorageService.getLoginUser();
  const { roles } = user;

  const filtered = useMemo(() => ROOT_MODULES.reduce((acc, module) => {
    if (roles?.some(item => (item?.authority)?.indexOf(module?.auth) !== -1)) {
      return [...acc, module]
    }
    return acc;
  }, []), [roles])

  const [list, setList] = useState([])
  const [isChild, setIsChild] = useState(false)

  useEffect(() => {
    setList(filtered)
  }, [])

  const { t } = useTranslation();

  return (
    <>
      <div 
        // className="bg-image"
        // style={{
        //   backgroundImage: "url(./assets/images/bg.jpg)",
        // }}
      >
      </div>
      <div className="content-wapper">
        <div className="container-wrapper">
          <LanguageSelect />
          <div className="header-title">
            <img src="./assets/images/logo.png" alt="Logo Vitimes" width={80} height={80} />
            <h1 className="header">
              {t("title1")}<br/>{t("title2")}
            </h1>
          </div>
          <div className="content container">
            {filtered?.length > 0 ?
            <div className="row flex-center">
              <Buttons list={list} setList={setList} setIsChild={setIsChild} />
            </div>
            :
            <h4 className="text-primary text-center">{t("requireRole")}</h4>}
          </div>
          <div className="footer">
            {isChild ?
              <button
                className="btn btn-secondary"
                onClick={() => {
                  setList(filtered)
                  setIsChild(false)
                }}
              >
                {t("back")}
              </button>
            :
              <button
                className="btn btn-secondary"
                onClick={jwtAuthService.logout}
              >
                {t("logout")}
              </button>
            }
          </div>
        </div>
      </div>
    </>
  );
};

const Buttons = ({ list, setList, setIsChild }) => {
  const { t } = useTranslation();
  return list.map((module, index) => {
    return (
      <Card
        key={index}
        to={(language) => {
          if (module?.children?.length > 0) {
            setList(module?.children);
            setIsChild(true)
          } else if (module.to) {
            // window.open(module.to, '_blank').focus();
            let lang = "";
            if (language === "en") {
              lang = "/en"
            }
            console.log("🔥🔥🔥 ~ file: DashboardIndex.jsx:100 ~ returnlist.map ~ module.to + lang:", module.to + lang)
            window.location.href = module.to + lang
          } else {
            alert(t("featureAlert"))
          }
        }}
      >
        {/* {module.title} */}
        {t(module.trans)}
      </Card>
    )
  })
}
import React from "react";
import { useTranslation } from "react-i18next";

export default function Card({
    children,
    to = (lang) => {},
}) {
    const { i18n } = useTranslation();

    return (
        <div className="card-wrapper col-xl-2 col-md-6 col-sm-12">
            <button
                className="btn btn-primary w-100"
                type="button"
                onClick={() => to(i18n.language)}
            >
                {children}
            </button>
        </div>
    );
}

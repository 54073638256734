import "../styles/_app.scss";
import React from "react";
import AppContext from "./appContext";
import routes from "./RootRoutes";
import Auth from "./auth/Auth";
import "../styles/nprogress.css";
import { loadProgressBar } from "axios-progress-bar";
import DashboardIndex from "./views/dashboard/DashboardIndex";
import { Router } from "react-router-dom";
import history from "history.js";
import { renderRoutes } from "react-router-config";

loadProgressBar();
const App = () => {
  return (
    <AppContext.Provider value={{ routes }}>
      <Auth>
        <Router history={history}>
          {renderRoutes(routes)}
        </Router>
      </Auth>
    </AppContext.Provider>
  );
};

export default App;

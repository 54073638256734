import ConstantList from "app/appConfig";
import DashboardIndex from "./DashboardIndex";
import LogoutIndex from "./LogoutIndex";

const Routes = [
  {
    path: ConstantList.ROOT_PATH,
    exact: true,
    component: DashboardIndex,
  },
  {
    path: ConstantList.ROOT_PATH + "logout",
    exact: true,
    component: LogoutIndex,
  },
];

export default Routes;
